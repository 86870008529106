.app {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.header {
  background-color: #000000;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.logo {
  background: #ffffff;
  margin-left: 20px;
  margin-right: 20px;
}
.form-login {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-inputs {
  display: flex;
  flex-direction: column;
}
footer{
  background-color: #000000;
  color: #ffffff !important;
}
.logout{
  cursor: pointer;
  text-decoration: underline;
}